import styled from "styled-components";
import { IoMdAdd } from "react-icons/io";

const Container = styled.button`
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--purple-500);
  border-radius: 2px;
  padding: 5px;

  transition: all 0.2s;

  svg {
    height: 24px;
    width: 24px;
  }

  &:hover {
    background-color: var(--purple-500);
    color: white;
  }


`;

const Plus = styled.text`
  font-size: 18px;
  font-weight: 700;
`;

export function PlusButton({ onClick }) {
  return <Container onClick={onClick}>
    <IoMdAdd />
  </Container>;
}
