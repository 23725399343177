import { useState } from 'react';
import {
  SearchResultCardItemStyle,
  SearchResultCardStyle,
  SubItemStyled,
} from './style';
import { Chevron } from '../../../../../assets/images/icons/chevron';

export const SearchResultCardItem = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (item) => props.onClick(item);

  const handleSubItemClick = (subItem, item) =>
    props.onSubItemClick(subItem, item);

  function handleGetColor(item) {
    const colorByType = {
      brand: '#2A7EC6',
      dosage: '#258138',
    };

    return item.color || colorByType[item?.type] || null;
  }

  function handleClickItem() {
    setIsOpen(!isOpen);
  }

  return (
    <SearchResultCardItemStyle isOpen={isOpen}>
      <div
        className={`search_result_card--item--header --track-search-mark-${props.type}`}
        data-item-id={props.item_id}
        onClick={handleClickItem}
      >
        <div className="search_result_card--item--header--title">
          <h4>{props.title}</h4>
          <small>{props.legend}</small>
        </div>
        <div className="search_result_card--item--header--icon">
          {/* {props.sub_items && props.sub_items.length > 0 && ( */}
            <Chevron
              isUpDirection={!isOpen}
              color={isOpen ? 'var(--yellow-500)' : 'var(--purple-500)'}
              width={16}
            />
          {/* ) 
         } */}
        </div>
      </div>
        <div className="search_result_card--item--sub_items">
          <SubItemStyled
              itemColor={'var(--purple-500)'}
              isOpen={isOpen}
              data-item-id={`${props.legend_id}${props.item_id}`}
              style={{ borderColor: `var(--yellow-500)` }}
              onClick={() => handleSubItemClick(null, props)}
            >
              <div className="search_result_card--item--sub_item--title">
                <h5>ADICIONAR LINK PARA ROTINA</h5>
                <span style={{color: 'var(--yellow-500)', fontSize: 12}}>{props.title}</span>
                <span style={{color: 'var(--yellow-500)', fontSize: 12}}>{props.legend}</span>
              </div>
            </SubItemStyled>
          {props?.sub_items?.map((sub_item) => (
            <SubItemStyled
              itemColor={handleGetColor(sub_item)}
              isOpen={isOpen}
              data-item-id={`${props.legend_id}${props.item_id}${sub_item.sub_item_id}`}
              style={{ borderColor: `${handleGetColor(sub_item)}` }}
              onClick={() => handleSubItemClick(sub_item, props)}
            >
              <div className="search_result_card--item--sub_item--title">
                <h5>{sub_item.title}</h5>
                <small>{sub_item.legend}</small>
              </div>
            </SubItemStyled>
          ))}
        </div>
    </SearchResultCardItemStyle>
  );
};

export const SearchResultCardComponent = ({
  title,
  icon,
  results,
  onItemClick,
  onSubItemClick,
  onShowMoreClick,
  style,
}) => {
  const [isResultsLimited, setIsResultsLimited] = useState(true);

  return (
    <SearchResultCardStyle
      style={style}
      isLimited={isResultsLimited}
      itHasNoResults={results.length === 0}
    >
      <div className="search_result_card--header">
        <h3>
          {icon}
          {title.toUpperCase()} <small>({results.length} resultados)</small>
        </h3>
      </div>
      {results.data.length > 0 && (
        <>
          <div className="search_result_card--body">
            {results.data
              .slice(0, isResultsLimited ? 3 : results.data.length)
              .map((result) => (
                <SearchResultCardItem
                  key={result.id}
                  {...result}
                  onClick={onItemClick}
                  onSubItemClick={onSubItemClick}
                />
              ))}
          </div>
          {results.data.length > 3 && (
            <div
              className="showMore__container"
              onClick={() => {
                onShowMoreClick(isResultsLimited);
                setIsResultsLimited(!isResultsLimited);
              }}
            >
              <span
                style={{
                  display: isResultsLimited ? 'block' : 'none',
                  zIndex: 1,
                }}
              >
                VER MAIS EM {title.toUpperCase()}
              </span>
              <span
                style={{
                  display: !isResultsLimited ? 'block' : 'none',
                  zIndex: 1,
                }}
              >
                VER MENOS
              </span>
            </div>
          )}
        </>
      )}
    </SearchResultCardStyle>
    // </CardComponent>
  );
};
