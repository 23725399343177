/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Pagination } from '../../atoms/pagination';
import { Container } from './style';
import { LoadingComponent } from '../../atoms/loadingComponent';
import { TableRow } from './tableRow';

export function VersionsTable({ versions }) {
  const [page, setPage] = useState(1);
  const [versionsArray, setVersionsArray] = useState(null);

  function handleVersionsArray() {
    setVersionsArray(versions?.slice((page - 1) * 10, page * 10));
  }

  useEffect(() => {
    if (versions) {
      handleVersionsArray();
    }
  }, [versions, page]);

  return (
    <Container>
      {versions ? (
        <>
          <table>
            <tr>
              <th>Versão</th>
              <th>Criação</th>
              <th>Publicação</th>
              <th></th>
            </tr>

            {versionsArray &&
              versionsArray.map((version) => (
                <TableRow version={version} />
              ))}
          </table>

          {versions?.length > 10 && (
            <Pagination
              currentPage={page}
              totalCountOfRegisters={versions.length}
              onPageChange={(e) => setPage(e)}
            />
          )}
        </>
      ) : (
        <LoadingComponent />
      )}
    </Container>
  );
}
