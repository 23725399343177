import styled from 'styled-components';

export const SearchResultCardStyle = styled.div`
   color: white;
   width: 100%;

   .search_result_card--header {
      display: flex;
      align-items: center;

      h3 {
         gap: 5px;
         display: flex;
         align-items: center;
         padding: 0;
         margin: 0;
         font-weight: bold;
         font-size: 16px;
         small {
            font-size: 12px;
            font-weight: 300;
         }
      }
   }

   .search_result_card--body {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
   }

   .showMore__container {
      height: 40px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 0 0 5px 5px;
      font-size: 14px;
      color: var(--purple-500);
      font-weight: bold;
      cursor: pointer;
      z-index: 2;

   }
`

export const SearchResultCardItemStyle = styled.div`
   overflow: visible;
   border: 1px solid gray;
   border-radius: 5px;
   h4 , h5{
      margin: 0;
   }
   small {
      color: gray;
   }

   &:last-child {
      margin-bottom: 0;
   }

   .search_result_card--item--header {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 5px 15px;
      box-sizing: border-box;
      min-height: 40px;

      z-index: 1;
      position: relative;

      border-radius: 5px 5px 0 0;
      /* border-bottom: 2px solid gray; */

      .search_result_card--item--header--title {
         display: flex;
         flex-direction: column;
         gap: 2px;

         h4 {
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
         }

         small {
            font-size: 11px;
         }
      }

      .search_result_card--item--header--icon {
         width: 55px;
         position: relative;
         cursor: pointer;
         color: var(--purple-500);
         display: flex;
         justify-content: flex-end;
         align-items: center;
         gap: 10px;


         .favorite__container {
            cursor: pointer;
            position: relative;
            top: -11px;

            svg {
               height: 24px;
               width: 24px;
               overflow: visible;

               transition: all 0.2s;

               &:hover {
                  filter: brightness(0.7);
               }

            }
         }
      }
   }
   
`

export const SubItemStyled = styled.div`

   display: ${props => props.isOpen ? 'block' : 'none'};
   
   border-style: solid;
   border-width: 1px;
   
   cursor: pointer;
   transition: all 0.2s;
   position: relative;
   overflow: hidden;
   box-sizing: border-box;
   &::after {
      content: 'Adicionar Link';
      width: fit-content;
      font-size: 16px;
      min-width: 100px;
      height: calc(100% + 1px);
      background-color: ${({ itemColor }) => itemColor};
      position: absolute;
      top: -1px;
      right: -200px;
      clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
      padding: 0 10px 0 30px;
      transition: all 0.2s;

      display: flex;
      align-items: center;
      justify-content: flex-end;
   }

   &:hover{
      &::after{
         right: 0;
      }
   }

   .search_result_card--item--sub_item--title {
      padding: 5px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2px;
      min-height: 40px;
      box-sizing: border-box;
      h5 {
         font-size: 14px;
         line-height: 16px;
      }

      small {
         color: ${({ itemColor }) => itemColor || 'white'};
         font-size: 11px;
      }
   }
   &:first-child {   
      border-top: 3px solid gray !important;
   }
   &:last-child {
      border-radius: 0 0 4px 4px;
      .search_result_card--item--sub_item--title {
         border-bottom: none;
      }
   }

`
