import { SearchResultCardComponent } from './search-result-card';
import { SearchContainerStyle } from './style';

export function SearchComponent({
  response,
  isLoading,
  linkCallback
}) {
  function handleClickItem({
    type,
    clickedItem,
    parent = null,
    hasSubItems = false,
  }) {
    console.log(
      'clicou no item \n',
      type,
      '\n',
      clickedItem,
      '\n',
      parent,
      '\n',
      hasSubItems
    );

    let fullLink = ''

    let calcSelected = null
    if (type === 'routine') {
      let parentLink = `${parent.item_id}`;

      let itemLink = !!clickedItem
        ? `/${clickedItem.legend_id}/${clickedItem.sub_item_id}`
        : '';

      fullLink = 'app/routines/' +  parentLink + itemLink;

    } else if (type === 'drug') {
      let parentLink = `${parent.legend_id}/${parent.item_id}`;

      let itemLink =
        !!clickedItem && clickedItem.type !== 'resume'
          ? `/${clickedItem.sub_item_id}`
          : '';

      fullLink = 'app/drugs/' + parentLink + itemLink;

    } else {
      fullLink = `app/calculators/${parent.item_id}`
      calcSelected = parent
    }

    linkCallback(fullLink, calcSelected)
  }

  function onShowMoreClick(type, results, isClickedToOpen) {
    if (!isClickedToOpen) return null;
  }

  function handleRenderSections(data) {
    console.log(data);
    const sections = {
      drugs: {
        title: 'Drogas',
        icon: null, //<BbDrugIcon primaryColor="#999999" />,
        results: data.drugs,
        onShowMoreClick: (isClickedToOpen) =>
          onShowMoreClick('drugs', data.drugs, isClickedToOpen),
      },
      routines: {
        title: 'Rotinas',
        icon: null, // <BbRoutineIcon primaryColor="#999999" />,
        results: data.routines,
        onShowMoreClick: (isClickedToOpen) =>
          onShowMoreClick('routines', data.routines, isClickedToOpen),
      },
      calculators: {
        title: 'Calculadoras',
        icon: null, //<Calculator secondaryColor="#999999" />,
        results: data.calculator,
        onShowMoreClick: (isClickedToOpen) =>
          onShowMoreClick('calculators', data.calculator, isClickedToOpen),
      },
    };

    return (
      <>
        {sections?.drugs?.results?.length > 0 ? (
          <SearchResultCardComponent
            hasFavorites={true}
            type="drugs"
            onItemClick={(item, hasSubItems) =>
              handleClickItem({
                type: 'drug',
                clickedItem: item,
                hasSubItems: hasSubItems,
              })
            }
            onSubItemClick={(item, parent) =>
              handleClickItem({
                type: 'drug',
                clickedItem: item,
                parent: parent,
              })
            }
            {...sections.drugs}
          />
        ) : (
          <></>
        )}

        {sections?.routines?.results?.length > 0 ? (
          <SearchResultCardComponent
            hasFavorites={true}
            type="routines"
            onItemClick={(item, hasSubItems) =>
              handleClickItem({
                type: 'routine',
                clickedItem: item,
                hasSubItems: hasSubItems,
              })
            }
            onSubItemClick={(item, parent) =>
              handleClickItem({
                type: 'routine',
                clickedItem: item,
                parent: parent,
              })
            }
            {...sections.routines}
          />
        ) : (
          <></>
        )}

        {sections?.calculators?.results?.length > 0 ? (
          <SearchResultCardComponent
            hasFavorites={false}
            onItemClick={(item) =>
              handleClickItem({
                type: 'calculator',
                clickedItem: item,
                hasSubItems: false,
              })
            }
            onSubItemClick={(item, parent) =>
              handleClickItem({
                type: 'calculator',
                clickedItem: item,
                parent: parent,
              })
            }
            {...sections.calculators}
          />
        ) : (
          <></>
        )}
      </>
    );
  }

  function validateEmptyAllSections(data) {
    console.log(data);
    return (
      data?.drugs?.length > 0 ||
      data?.routines?.length > 0 ||
      data?.calculator?.length > 0
    );
  }

  return (
    <SearchContainerStyle>
      {isLoading ? (
        <div className="default__container">
          Buscando....
        </div>
      ) : response?.error ? (
        <div className="default__container">
          Ocorreu um erro. Tente novamente mais tarde.
        </div>
      ) : validateEmptyAllSections(response) ? (
        handleRenderSections(response)
      ) : (
        <div className="emptySearch__container">
          {/* <EmptySearchImage /> */}
          <h3>
            {!!response?.searchResult
              ? 'Não encontramos nenhum resultado'
              : 'Busque por ROTINAS, DROGAS e CALCULADORAS!'}
          </h3>
        </div>
      )}
    </SearchContainerStyle>
  );
}
