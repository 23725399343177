import { useContext, useEffect, useState } from 'react';
import { LoadingComponent } from '../../atoms/loadingComponent';
import { NewsList } from '../newsList';
import { CloseButton, SaveButton } from './style';
import { IoMdClose } from 'react-icons/io';
import { ContextAplication } from '../../../context';

export function EditReleaseNotes({ onClose, version }) {
  const [newsCards, setNewsCards] = useState([]);
  const [comingSoonCards, setComingSoonCards] = useState([]);

  const [loading, setLoading] = useState(false);

  const { handleGet, handleCreate, handleDelete, handleUpdate } =
    useContext(ContextAplication);

  async function handleSubmit() {
    setLoading(true);
    try {
      const filteredNewsCards = newsCards.filter((e) => !e?._id);
      const filteredUpcomingCards = comingSoonCards.filter((e) => !e?._id);

      if (filteredNewsCards.length > 0 || filteredUpcomingCards.length > 0) {
        let parsedNewsCards = filteredNewsCards.map((card) => {
          if (card.link?.includes('calculators')) {
            return {
              title: card.title,
              description: card.description,
              type: 'update',
              version: version.version,
              sub_version: version.sub_version,
              is_home: false,
              link: card.link,
              codepush_android: card.calcSelected.codepush_android,
              codepush_ios: card.calcSelected.codepush_ios,
            };
          }
          return {
            title: card.title,
            description: card.description,
            type: 'update',
            version: version.version,
            sub_version: version.sub_version,
            is_home: false,
            link: card.link,
          };
        });
        let parsedComingSoonCards = filteredUpcomingCards.map((card) => {
          return {
            description: card.description,
            type: 'upcoming',
            version: version.version,
            sub_version: version.sub_version,
          };
        });
        const concat = [...parsedNewsCards, ...parsedComingSoonCards];
        const response = await handleCreate(
          'version/v2/release-note/create',
          concat
        );
      }

      onClose();
    } catch (error) {}
    setLoading(false);
  }

  async function handleGetData() {
    setLoading(true);
    try {
      const response = await handleGet(
        `version/v2/release-note/${version.version + '.' + version.sub_version}`
      );

      setNewsCards(response.update);
      setComingSoonCards(response.upcoming);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    handleGetData();
  }, []);

  async function handleCardsToRemove(type, card) {
    setLoading(true);
    try {
      const response = await handleDelete(
        `version/v2/release-note/delete/${card._id}`
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  async function handleSaveCard(type, card_id) {
    setLoading(true);
    try {
      let card =
        type === 'update'
          ? newsCards.find((e) => e._id === card_id)
          : comingSoonCards.find((e) => e._id === card_id);

      if (card.link?.includes('calculators') && !!card.calcSelected) {
        card = {
          ...card,
          codepush_android: card.calcSelected.codepush_android,
          codepush_ios: card.calcSelected.codepush_ios,
        };
      }

      const { calcSelected, ...rest } = card;

      card = rest;

      const response = await handleUpdate(
        `version/v2/release-note/update/${card_id}`,
        card
      );
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'flex-start',
        }}
      >
        <h2>Notas sobre a atualização</h2>
        <CloseButton
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 32,
            width: 32,
            padding: 0,
          }}
          onClick={onClose}
        >
          <IoMdClose style={{ height: 24, width: 24 }} />
        </CloseButton>
      </div>
      <NewsList
        newsCards={newsCards}
        setNewsCards={setNewsCards}
        comingSoonCards={comingSoonCards}
        setComingSoonCards={setComingSoonCards}
        removeCallback={handleCardsToRemove}
        saveCallback={handleSaveCard}
      />

      <SaveButton onClick={handleSubmit}>
        Salvar notas de atualização
      </SaveButton>

      {loading && <LoadingComponent />}
    </>
  );
}
