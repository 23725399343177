import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  background-color: var(--gray-700);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-900);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--gray-700);
  }

  header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2rem;

    h1 {
      font-size: 2rem;
      color: var(--white);
      margin-bottom: 0.5rem;
    }
  }

  body {
    background-color: var(--gray-500);

    width: 100%;
    border-radius: 5px;
  }
`;
