import React, { useState, useContext, useEffect } from 'react';
import { DefaultTemplate } from '../../components/templates/defaultTemplates';
import {
  Container,
  Modal,
  ModalContent,
  ModalOverlay,
  CloseButton,
} from './style';
import { IoCheckmarkCircle, IoHourglassOutline } from 'react-icons/io5';
import { ContextAplication } from '../../context';
import { VersionsTable } from '../../components/molecules/versionsTable';
import { LoadingComponent } from '../../components/atoms/loadingComponent';
import { GenericModalComponent } from '../../components/atoms/genericModal';
import { NewVersionModal } from '../../components/organisms/NewVersionModal';
import { FiRefreshCcw } from "react-icons/fi";
import api from '../../services/axios-search';
import { toast } from 'react-toastify';

export function Versions() {
  const { handleGet, handleCreate } = useContext(ContextAplication);
  const [versions, setVersions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newVersion, setNewVersion] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisableSyncSearch, setIsDisableSyncSearch] = useState(false);

  const steps = [
    {
      title: 'Folha de parada',
      key: 'stopSheet',
    },
    {
      title: 'Rotinas',
      key: 'routine',
    },
    {
      title: 'Drogas',
      key: 'drug',
    },
    {
      title: 'Especialidades',
      key: 'specialty',
    },
    {
      title: 'Home',
      key: 'home',
    },
    {
      title: 'Busca mobile',
      key: 'search',
    },
    {
      title: 'Imagens da home',
      key: 'imageHome',
    },
    {
      title: 'Pacote de imagens iniciais',
      key: 'imageZip',
    },
  ];

  async function handleUpdateSearch() {
    setIsDisableSyncSearch(true);
    try {
      await api.post(`/search-content-sync`, {
        headers: {
          'Content-Type': 'application/json',
          origin: 'admin',
        },
      });
      toast.success('Aguarde alguns minutos para que atualize a busca...');
    } catch (error) {
      toast.error('Ocorreu algum erro com a atualização...');
    }
    await new Promise(resolve => setTimeout(resolve, 300000));
    setIsDisableSyncSearch(false);
  }

  async function handleGetVersions() {
    const response = await handleGet('version/v2/all');
    setVersions(response);
  }

  async function handleNewVersion() {
    setLoading(true);
    const response = await handleCreate('version/v2/create', {});

    if (response?.version) {
      setNewVersion(response);
      await handleGetVersions();
    }
    setConfirmModal(false);
    setLoading(false);
  }

  async function handleGetOneVersion(v) {
    setLoading(true);
    const response = await handleGet(`version/v2/${v._id}`);
    setNewVersion(response);
    setLoading(false);
    await handleGetVersions();
  }

  useEffect(() => {
    handleGetVersions();
  }, []);

  return (
    <DefaultTemplate>
      <Container>
        <div className="header">
          <div className="header__title">
            <h2>Versões do conteúdo (realm)</h2>
          </div>
          {/* <button onClick={() => setConfirmModal(true)}> */}
          <div
            className='container_button'
          >
            <button 
              onClick={async () => !isDisableSyncSearch ? await handleUpdateSearch(): null}
              disabled={isDisableSyncSearch}
            >
              <FiRefreshCcw 
                style={{marginRight: 5}}
                color="#fff" 
              />
              Atualizar a busca
            </button>
            <button onClick={() => setIsModalOpen(true)}>
              Gerar nova versão
            </button>
          </div>
        </div>

        <VersionsTable versions={versions} />
      </Container>
      {loading && <LoadingComponent />}

      <GenericModalComponent
        key={'modal_new_version'}
        isOpen={Boolean(newVersion)}
        title={'Detalhes da Versão'}
        onCloseButton={() => setNewVersion(null)}
        onConfirmButton={() => handleGetOneVersion(newVersion)}
        textConfirmButton={'ATUALIZAR STATUS'}
        children={
          <>
            <p>
              <strong>Versão:</strong>{' '}
              {`${newVersion?.version}.${newVersion?.sub_version}`}
            </p>
            <p>
              <strong>Criação:</strong>{' '}
              {new Date(newVersion?.createdAt).toLocaleString('pt-BR', {
                timeZone: 'America/Sao_Paulo',
              })}
            </p>
            <p>
              <strong>Publicação:</strong>{' '}
              {newVersion?.publishedAt
                ? new Date(newVersion?.publishedAt).toLocaleString('pt-BR', {
                    timeZone: 'America/Sao_Paulo',
                  })
                : 'Pendente'}
            </p>
            <h3>Etapas:</h3>
            <ul>
              {steps.map((step, stepIndex) => (
                <li key={stepIndex}>
                  <span>{step?.title}</span>
                  {newVersion?.steps && newVersion?.steps[step?.key] ? (
                    <IoCheckmarkCircle color="green" />
                  ) : (
                    <IoHourglassOutline color="var(--red-300)" />
                  )}
                </li>
              ))}
            </ul>
          </>
        }
      />

      <GenericModalComponent
        key={'modal_confirm_new_version'}
        isOpen={confirmModal}
        title={'Deseja realmente gerar uma nova versão?'}
        onCloseButton={() => setConfirmModal(false)}
        onConfirmButton={() => handleNewVersion()}
        textCloseButton={'CANCELAR'}
        textConfirmButton={'GERAR'}
        children={
          <p>
            <strong>
              Essa ação é irreversível e não pode ser interrompida!
            </strong>
          </p>
        }
      />
      {newVersion && (
        <Modal>
          <ModalOverlay onClick={() => setNewVersion(null)} />
          <ModalContent>
            <CloseButton onClick={() => setNewVersion(null)}>X</CloseButton>
            <h2>Detalhes da Versão</h2>
            {newVersion && (
              <>
                <p>
                  <strong>Versão:</strong>{' '}
                  {`${newVersion?.version}.${newVersion?.sub_version}`}
                </p>
                <p>
                  <strong>Criação:</strong>{' '}
                  {new Date(newVersion?.createdAt).toLocaleString('pt-BR', {
                    timeZone: 'America/Sao_Paulo',
                  })}
                </p>
                <p>
                  <strong>Publicação:</strong>{' '}
                  {newVersion?.publishedAt
                    ? new Date(newVersion?.publishedAt).toLocaleString(
                        'pt-BR',
                        { timeZone: 'America/Sao_Paulo' }
                      )
                    : 'Pendente'}
                </p>
                <h3>Etapas:</h3>
                <ul>
                  {steps.map((step, stepIndex) => (
                    <li key={stepIndex}>
                      <span>{step?.title}</span>
                      {newVersion?.steps && newVersion?.steps[step?.key] ? (
                        <IoCheckmarkCircle color="green" />
                      ) : (
                        <IoHourglassOutline color="var(--red-300)" />
                      )}
                    </li>
                  ))}
                </ul>
                <button onClick={() => handleGetOneVersion(newVersion)}>
                  Atualizar status
                </button>
              </>
            )}
          </ModalContent>
        </Modal>
      )}

      {isModalOpen && (
        <Modal>
          <ModalOverlay>
            <ModalContent
              style={{
                gap: 10,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#000',
              }}
            >
              <NewVersionModal
                onClickToGenerateNewVersion={(e) => setNewVersion(e)}
                onClose={() => setIsModalOpen(false)}
              />
            </ModalContent>
          </ModalOverlay>
        </Modal>
      )}
    </DefaultTemplate>
  );
}
