import {
  IoCheckmarkCircle,
  IoChevronDown,
  IoChevronUp,
  IoHourglassOutline,
} from 'react-icons/io5';
import {
  IconButton,
  AccordionContent,
  ReleaseNotesButton,
  Modal,
  ModalContent,
  ModalOverlay,
} from './style';

import { useState } from 'react';
import { EditReleaseNotes } from '../editReleaseNotes';

export function TableRow({ version }) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isReleaseNotesModalOpen, setIsReleaseNotesModalOpen] = useState(false);

  const steps = [
    {
      title: 'Folha de parada',
      key: 'stopSheet',
    },
    {
      title: 'Rotinas',
      key: 'routine',
    },
    {
      title: 'Drogas',
      key: 'drug',
    },
    {
      title: 'Especialidades',
      key: 'specialty',
    },
    {
      title: 'Home',
      key: 'home',
    },
    {
      title: 'Busca mobile',
      key: 'search',
    },
    {
      title: 'Imagens da home',
      key: 'imageHome',
    },
    {
      title: 'Pacote de imagens iniciais',
      key: 'imageZip',
    },
  ];

  return (
    <>
      <tr>
        <td>{`${version?.version}.${version?.sub_version}`}</td>
        <td>
          {new Date(version?.createdAt).toLocaleString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
          })}
        </td>
        <td>
          {version?.publishedAt
            ? new Date(version?.publishedAt).toLocaleString('pt-BR', {
                timeZone: 'America/Sao_Paulo',
              })
            : 'Gerando'}
          {version?.publishedAt ? (
            <IoCheckmarkCircle color="green" />
          ) : (
            <IoHourglassOutline color="var(--red-300)" />
          )}
        </td>
        <td>
          <IconButton onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
            {isAccordionOpen ? <IoChevronUp /> : <IoChevronDown />}
          </IconButton>
        </td>
      </tr>
      {isAccordionOpen && (
        <tr>
          <td colSpan="4">
            <AccordionContent>
              <ul>
                {steps.map((step, stepIndex) => (
                  <li key={stepIndex}>
                    <span>{step?.title}</span>
                    {version?.steps && version?.steps[step?.key] ? (
                      <IoCheckmarkCircle color="green" />
                    ) : (
                      <IoHourglassOutline color="var(--red-300)" />
                    )}
                  </li>
                ))}
              </ul>

              <ReleaseNotesButton
                onClick={() => setIsReleaseNotesModalOpen(true)}
              >
                Ver notas da versão
              </ReleaseNotesButton>
            </AccordionContent>
          </td>
        </tr>
      )}

      {isReleaseNotesModalOpen && (
        <Modal>
          <ModalOverlay>
            <ModalContent
              style={{
                gap: 10,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#000',
              }}
            >
              <EditReleaseNotes version={version} onClose={() => setIsReleaseNotesModalOpen(false)} />
            </ModalContent>
          </ModalOverlay>
        </Modal>
      )}
    </>
  );
}
