import styled from 'styled-components';

export const SearchContainerStyle = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding-right: 15px;
    overflow-y: scroll;

    height: 100%;

    .default__container {
      display: flex;
      justify-content: center;

      color: white;
    }

  .emptySearch__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0.5;

    height: inherit;
    gap: 15px;
    svg {
      height: 40%;
    }

    h3 {
      color: #ccd2d6;
      text-align: center;
    }
  }
`;
