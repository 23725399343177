import React from 'react';
import { useLocation } from 'react-router-dom';

import { HiUsers, HiTemplate } from 'react-icons/hi';
import { AiFillMedicineBox } from 'react-icons/ai';
import { FaUserTie, FaUser, FaUsers, FaFileCode } from 'react-icons/fa';
import { IoNotificationsSharp, IoSettingsSharp, IoGitBranch } from 'react-icons/io5';
import { GrMail } from 'react-icons/gr';
import { MdMessage, MdSystemUpdate } from 'react-icons/md';
import { RiCoupon3Fill, RiApps2Fill, RiUserStarFill } from 'react-icons/ri';

import SideBar from '../../organisms/sideBar';
import logo from '../../../assets/images/logo.png';

const sideBarOptions = [
  {
    name: 'Usuários',
    href: '/users',
    icon: <HiUsers color="#fff" />,
  },
  {
    name: 'Áreas de Atuação',
    href: '/actuationareas',
    icon: <AiFillMedicineBox color="#fff" />,
  },
  {
    name: 'Painel de Mensagens',
    hasChildren: true,

    icon: <MdMessage color="#fff" />,
    childrens: [
      {
        name: 'Notificação Push',
        href: '/notifications',
        icon: <IoNotificationsSharp color="#fff" />,
      },
      {
        name: 'E-mails',
        href: '/emails/create',
        icon: <GrMail color="#fff" />
      },
      {
        name: 'Templates',
        href: '/emailstemplates',
        icon: <FaFileCode color="#fff" />
      },
      {
        name: 'Changelog',
        href: '/changelog',
        icon: <MdSystemUpdate color="#fff" />
      }
    ]
  },
  {
    name: 'Painel de Administração',
    hasChildren: true,

    icon: <FaUserTie color="#fff" />,
    childrens: [
      {
        name: 'Grupos',
        href: '/groupadmin',
        icon: <FaUsers color="#fff" />,
      },
      {
        name: 'Usuários',
        href: '/useradmin',
        icon: <FaUser color="#fff" />,
      },
      {
        name: 'Cupons',
        href: 'couponadmin',
        icon: <RiCoupon3Fill color="#fff" />,
      }
    ],
  },
  {
    name: 'Painel do Aplicativo',
    hasChildren: true,

    icon: <RiApps2Fill color="#fff" />,
    childrens: [
      {
        name: 'Versões do conteúdo',
        href: '/versions',
        icon: <IoGitBranch color="#fff" />,
      },
      {
        name: 'Configuração da Home',
        href: '/appsettings',
        icon: <IoSettingsSharp color="#fff" />,
      },
      {
        name: 'Banners',
        href: '/banners',
        icon: <HiTemplate color="#fff" />,
      },
    ],
  },
];

export function MenuTemplate() {
  const location = useLocation();

  return (
    <SideBar
      open={true}
      selected={location.pathname}
      sideBarOptions={sideBarOptions}
      logo={logo}
    />
  );
}
