import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  overflow: auto;
`;

export const ModalContent = styled.div`
  background-color: var(--gray-900);
  color: white;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  z-index: 2;
  max-width: 500px;
  width: 100%;

  p {
    margin-bottom: 0.5rem;
  }
  
  h2, h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      svg {
        margin-left: 8px;
      }
    }
  }

  .divHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  .divCloseButton {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .divTitle {
    width: 90%;
  }

  .divActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
  }
`;

export const CloseButton = styled.button`
  background-color: var(--red-500);
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  padding: 0.6rem 1rem;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ConfirmButton = styled.button`
  background-color: green;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  padding: 0.6rem 1.2rem;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
`;