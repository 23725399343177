
export const Chevron = ({isUpDirection = false, color = '#999', width = 12, height = 8}) => (
  <svg 
      width={width}
      height={height}
      viewBox="0 0 12 8" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg" 
      style={{transform: isUpDirection ? 'rotate(180deg)' : ''}}
  >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.41 8L6 3.42L10.59 8L12 6.59L6 0.59L1.23266e-07 6.59L1.41 8Z" fill={color}/>
  </svg>
)
