import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--gray-500);
  color: white;
  padding: 1.2rem;
  border-radius: 5px;
  width: 100%;

  table {
    width: 100%;
    
    tr {
      height: 3rem;
      border-bottom: 1px solid var(--gray-300);
      vertical-align: middle;

      th {
        font-size: 1.2rem;
      }

      th, td {
        vertical-align: middle;
        text-align: left;
      }

      svg {
        font-size: 1.2rem;
        margin-left: 10px;
        vertical-align: text-top;
      }

      a:visited {
        color: #3104ee;
      }
    }
  }
`;

export const AccordionContent = styled.div`
  background-color: var(--gray-700);
  padding: 1rem;
  border-radius: 4px;
  margin-top: 0.5rem;

  ul {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      svg {
        margin-right: 8px;
      }

      span {
        font-size: 1rem;
      }
    }
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--gray-300);
  }
`;


export const ReleaseNotesButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--purple-500);
  color: var(--purple-500);
  padding: 5px 15px;
  border-radius: 5px;
  margin-top: 10px;

  transition: all 0.2s;

  &:hover {
    background-color: var(--purple-500);
    color: white;
  }
`


export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  overflow: auto;
`;

export const ModalContent = styled.div`
  background-color: var(--gray-900);
  color: white;
  padding: 2rem;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  max-width: 500px;
  width: 100%;
  margin: 2rem auto;

  p {
    margin-bottom: 0.5rem;
  }
  
  h2, h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      svg {
        margin-left: 8px;
      }
    }
  }

  .divHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  .divCloseButton {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  .divTitle {
    width: 90%;
  }

  .divActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
  }
`;
