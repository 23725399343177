import styled from 'styled-components';

export const CloseButton = styled.button`
  background-color: var(--red-500);
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  padding: 0.6rem 1rem;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ConfirmButton = styled.button`
  background-color: green;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  padding: 0.6rem 1.2rem;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
`;


export const SaveButton = styled.button`
  background-color: var(--purple-500);
  align-self: flex-end;
  padding: 15px 30px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  cursor: pointer;

  transition: all 0.2s;
  &:hover {
    filter: brightness(0.8);
  }
`