import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { IoMdTrash } from 'react-icons/io';
import { AutoResizeTextarea } from '../../atoms/autoResizeTextarea';
import { ModalLink } from '../modalLinkBySearch';

import { debounce } from 'lodash';

const Container = styled.form`
  background-color: #282828;
  padding: 28px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;

  .validatorLink__btn {
    position: absolute;
    bottom: 28px;
    padding: 10px 0;

    color: #ffffff;
    text-decoration-color: var(--purple-500);

    transition: all 0.2s;
    &:hover {
      color: var(--purple-500);
    }
  }
`;

const InputSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.input`
  background-color: #7415ed;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  color: white;
  font-weight: 700;
  ::placeholder {
    color: white;
    font-size: 12px;
  }
`;

const Close = styled.button`
  width: 28px !important;
  height: 28px !important;
  background-color: transparent;
  border: none;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  color: var(--red-500);
  border-radius: 5px;

  position: absolute;
  right: 28px;
  padding: 5px;

  transition: all 0.2s;

  &:hover {
    background-color: #f5005744;
  }

  svg {
    height: 24px;
    width: 24px;
  }
`;

const LinkButton = styled.button`
  background-color: transparent;

  border: 1px solid var(--purple-500);
  border-radius: 5px;
  color: var(--purple-500);
  padding: 5px 15px;
  width: fit-content;
  align-self: flex-end;
  margin-top: 10px;

  transition: all 0.2s;
  &:hover {
    color: white;
    background-color: var(--purple-500);
  }

  &.filled {
    color: white;
    background-color: var(--purple-500);

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export function NewsCard({
  card,
  onUpdate,
  hasLink,
  hasTitle,
  onRemove,
  saveCallback,
}) {
  const [title, setTitle] = useState(card.title);
  const [description, setDescription] = useState(card.description);
  const [link, setLink] = useState(card.link);
  const [calcSelected, setCalcSelected] = useState(card.calcSelected);

  const [openModalLink, setOpenModalLink] = useState(false);

  const handleUpdate = (e) => {
    e.preventDefault();
    onUpdate(title, description, link);
  };

  const debouncedUpdate = useCallback(
    debounce((title, description, link, calcSelected) => {
      onUpdate(title, description, link, calcSelected);
    }, 300),
    [onUpdate]
  );

  const handleTitleChange = (e) => {
    const newTitle = e;
    setTitle(newTitle);
    debouncedUpdate(newTitle, description, link, calcSelected);
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e;
    setDescription(newDescription);
    debouncedUpdate(title, newDescription, link, calcSelected);
  };
  const handleLinkChange = (e, newCalcSelected) => {
    const newLink = e;

    setLink(newLink);
    setCalcSelected(newCalcSelected);
    debouncedUpdate(title, description, newLink, newCalcSelected);
    setOpenModalLink(false);
  };

  return (
    <>
      <Container
        //  onChange={handleUpdate}
        onSubmit={handleUpdate}
      >
        <Close onClick={onRemove}>
          <IoMdTrash />
        </Close>
        {hasTitle && (
          <InputSection>
            <Title
              type="text"
              placeholder="Título da atualização"
              value={title}
              onChange={(e) => handleTitleChange(e.target.value)}
            />
          </InputSection>
        )}

        <InputSection style={{ width: 'calc(100% - 32px)' }}>
          <AutoResizeTextarea
            style={{
              width: '100%',
              backgroundColor: 'transparent',
              border: 'none',
              color: 'white',
            }}
            rows="1"
            type="text"
            placeholder="Escreva aqui um breve texto sobre o conteúdo"
            value={description}
            onChange={handleDescriptionChange}
          />
        </InputSection>

        {link && (
          <a
            className='validatorLink__btn'
            href={process.env.REACT_APP_WEB_APP_URL + link}
            target="_blank"
            rel="noreferrer"
          >
            Acessar Link adcionado
          </a>
        )}
        {hasLink && (
          <LinkButton onClick={() => setOpenModalLink(true)}>
            Adicionar Link
          </LinkButton>
        )}

        {!!saveCallback && card._id && (
          <LinkButton onClick={() => saveCallback(card._id)} className="filled">
            Salvar alterações
          </LinkButton>
        )}
      </Container>
      {openModalLink && (
        <ModalLink
          setOpenModalLink={setOpenModalLink}
          linkCallback={handleLinkChange}
          content={link}
        />
      )}
    </>
  );
}
