import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_SEARCH_API,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'origin': 'admin',
    'Authorization' : process.env.REACT_APP_SEARCH_TOKEN
  },
});

export default api;
