import styled from 'styled-components';
import { PlusButton } from '../../atoms/plusButton';
import { NewsCard } from '../newsCard';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled.h3`
  margin-bottom: 0 !important;
`;

const Section = styled.div`
  background-color: #000;
  border-radius: 5px;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #282828;
`;

export function NewsList({
  newsCards,
  setNewsCards,
  comingSoonCards,
  setComingSoonCards,
  removeCallback = () => null,
  saveCallback = () => null,
}) {
  const handleUpdateCard = (
    index,
    updatedTitle,
    updatedContent,
    updatedLink,
    calcSelected
  ) => {
    const updatedNewsCards = [...newsCards];
    updatedNewsCards[index] = {
      ...updatedNewsCards[index],
      title: updatedTitle,
      description: updatedContent,
      link: updatedLink,
      calcSelected: calcSelected
    };
    setNewsCards(updatedNewsCards);
  };

  const handleUpdateComingSoonCard = (index, updatedTitle, updatedContent) => {
    const updatedComingSoonCards = [...comingSoonCards];
    updatedComingSoonCards[index] = {
      ...updatedComingSoonCards[index],
      title: updatedTitle,
      description: updatedContent,
    };
    setComingSoonCards(updatedComingSoonCards);
  };

  const handleAddNewsCard = (e) => {
    e.preventDefault();
    setNewsCards([...newsCards, { title: '', description: '', link: '' }]);
  };

  const handleAddComingSoonCard = (e) => {
    e.preventDefault();
    setComingSoonCards([...comingSoonCards, { description: '' }]);
  };

  const handleRemoveNewsCard = (index) => {
    let aux = newsCards;

    setNewsCards(null);

    let removedCard = aux.splice(index, 1)[0];

    setTimeout(() => {
      setNewsCards(aux);
      removeCallback('update', removedCard);
    }, 100);
  };

  const handleRemoveComingSoonCard = (index) => {
    let aux = comingSoonCards;

    setComingSoonCards(null)

    let removedCard = aux.splice(index, 1)[0];

    setTimeout(() => {
      setComingSoonCards(aux);
      removeCallback('upcoming', removedCard);
    }, 100);
  };

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <Title>Conteúdo novo</Title>
      <Section>
        {newsCards?.map((card, index) => (
          <NewsCard
            hasLink={true}
            hasTitle={true}
            key={card?._id || index}
            card={card}
            onRemove={() => handleRemoveNewsCard(index)}
            onUpdate={(updatedTitle, updatedContent, updatedLink, calcSelected) =>
              handleUpdateCard(index, updatedTitle, updatedContent, updatedLink, calcSelected)
            }
            saveCallback={
              !!saveCallback ? () => saveCallback('update', card._id) : null
            }
          />
        ))}
        <PlusButton onClick={handleAddNewsCard} />
      </Section>
      <Divider />
      <Title>Conteúdo futuro</Title>
      <Section>
        {comingSoonCards?.map((card, index) => (
          <NewsCard
            key={card?._id || index}
            card={card}
            onUpdate={(updatedTitle, updatedContent) =>
              handleUpdateComingSoonCard(index, updatedTitle, updatedContent)
            }
            onRemove={() => handleRemoveComingSoonCard(index)}
            saveCallback={
              !!saveCallback ? () => saveCallback('upcoming', card._id) : null
            }
          />
        ))}
        <PlusButton onClick={handleAddComingSoonCard} />
      </Section>
    </Container>
  );
}
