import { useRef } from 'react';
import styled from 'styled-components';

const Styles = styled.textarea`
  overflow: hidden;
  resize: none;
  flex: 1;
`;

export function AutoResizeTextarea({ value, onChange, style, placeholder }) {
  const textareaRef = useRef(null);

  const handleInput = () => {
    textareaRef.current.style.height = 'auto';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  function handleStartedRows() {
    let aux = textareaRef?.current?.scrollHeight;
    if (aux) {
      handleInput();
    }
    return '1';
  }

  return (
    <Styles
      style={style}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      ref={textareaRef}
      rows={handleStartedRows()}
      placeholder={placeholder}
      onInput={handleInput}
    />
  );
}
