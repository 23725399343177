import { useContext, useEffect, useState } from 'react';
import { useFormik, Field, FieldArray, FormikProvider } from 'formik';
import { useHistory } from 'react-router-dom';

import { FaMinus, FaPlus } from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ModalLink } from '../modalLinkBySearch';

import { InputComponent } from '../../atoms/inputComponent';
import { ContextAplication } from '../../../context';

import { UserFormComponent, FilterContainer } from './style';

export function NotificationsForm({ notification = null, isCreate = false }) {
  const { handleCreate, handleGet, handleUpdate } =
    useContext(ContextAplication);

  const [isModalLinkOpen, setIsModalLinkOpen] = useState(false);
  const [link, setLink] = useState(notification?.link || null);

  const handleLinkChange = (e) => {
    const newLink = e;

    setLink(newLink);
    setIsModalLinkOpen(false);
  };

  const history = useHistory();

  const [actuationAreas, setActuationAreas] = useState(null);
  const [states, setStates] = useState(null);
  const [usersList, setUsersList] = useState(null);
  const [whoIsOpen, setWhoIsOpen] = useState(null);

  let timeout = null;

  async function handleGetUsers(path, param) {
    if (path === 'users/auth') {
      const emailsResponse = await handleGet(`auths/email/${param}`);

      let emailsIds = [];
      const parsedUsers = emailsResponse.map((email) => ({
        ...email.user,
        auth: {
          _id: email._id,
          email: email.email,
        },
      }));
      console.log({ parsedUsers });
      setUsersList(parsedUsers);
      return;
    }
  }

  function debounceInput(field, param) {
    if (param.length < 3) {
      return;
    }

    clearTimeout(timeout);
    timeout = setTimeout(async function () {
      setUsersList([]);
      return handleGetUsers(field, param);
    }, 800);
  }

  async function handleGetActuationAreasAndStates() {
    const response = await handleGet('actuation-areas');
    const responseStates = await handleGet('states');

    setActuationAreas(response);
    setStates(responseStates);
  }

  useEffect(() => {
    handleGetActuationAreasAndStates();
  }, []);

  const formikSendTo = useFormik({
    initialValues: {
      send_to_bell: false,
      send_to_push: true,
    },
  });

  const formik = useFormik({
    initialValues: {
      title: notification?.title || '',
      content: notification?.content || '',
      send_to_all_users: notification ? notification?.send_to_all_users : true,
      users_id: notification?.users_id || [],
      actuation_areas_id: notification?.actuation_areas_id || [],
      states_id: notification?.states_id || [],
    },

    onSubmit: async (values) => {
      try {
        let response = null;
        let usersArray = [];

        if (values.send_to_all_users) {
          values.users_id = [];
          values.actuation_areas_id = [];
          values.states_id = [];
        } else {
          if (whoIsOpen === 'users') {
            values.actuation_areas_id = [];
            values.states_id = [];

            usersArray = values.users_id.map((user) => {
              return user._id;
            });
          } else if (whoIsOpen === 'filters') {
            values.users_id = [];
          }
        }

        isCreate
          ? (response = await handleCreate('notifications', {
              ...values,
              users_id: usersArray,
              is_enabled_push: formikSendTo.values.send_to_push,
              is_activity: formikSendTo.values.send_to_bell,
              link: link,
            }))
          : (response = await handleUpdate(
              `notifications/${notification?._id}`,
              { ...values, users_id: usersArray }
            ));

        return response;
      } catch (error) {
        console.error(error);
      }
    },
  });

  function handleFormSubmit(e) {
    e.preventDefault();
    try {
      formik.handleSubmit();
    } catch (error) {
      console.log(error);
      return;
    }
  }

  return (
    <>
      <UserFormComponent onSubmit={handleFormSubmit}>
        <FormikProvider value={formik}>
          <div className="input__container">
            <label>
              <span>Título</span>
              <input
                id={'title'}
                type={'text'}
                name={'title'}
                onChange={formik.handleChange}
                value={formik.values.title}
              />
            </label>
            <label>
              <span>Descrição</span>
              <input
                id={'content'}
                label={'Conteúdo'}
                type={'textarea'}
                name={'content'}
                onChange={formik.handleChange}
                value={formik.values.content}
              />
            </label>
            <button
              className="link__btn"
              type="button"
              onClick={() => setIsModalLinkOpen(true)}
            >
              Adicionar Link
            </button>
            {link && (
              <a
                className="valitade-link__btn"
                href={process.env.REACT_APP_WEB_APP_URL + link}
                target="_blank"
                rel="noreferrer"
              >
                Validar Link Adicionado
              </a>
            )}
          </div>
          <hr />
          {isCreate && (
            <label className="checkbox">
              <input
                id={'send_to_all_users'}
                type={'checkbox'}
                name={'send_to_all_users'}
                onChange={formik.handleChange}
                checked={formik.values.send_to_all_users}
                value={formik.values.send_to_all_users}
              />
              <span>Enviar para todos os usuários</span>
            </label>
          )}

          {isCreate ? (
            !formik.values.send_to_all_users && (
              <FilterContainer whoIsOpen={whoIsOpen}>
                <h1>Filtrar por</h1>
                <div className="filters">
                  <h3 onClick={() => setWhoIsOpen('filters')}>
                    Áreas de Atuação
                  </h3>
                  {actuationAreas && (
                    <FieldArray
                      name="actuation_areas_id"
                      render={(arrayHelpers) => (
                        <div className="select-container">
                          <div className="header">
                            <h2>Selecionar Áreas de Atuação</h2>
                            <button
                              type="button"
                              onClick={() =>
                                arrayHelpers.insert(
                                  formik.values.actuation_areas_id.length,
                                  ''
                                )
                              } // adiciona mais uma opção de select
                            >
                              <FaPlus />
                            </button>
                          </div>

                          {formik.values.actuation_areas_id &&
                            formik.values.actuation_areas_id?.map(
                              (_, index) => (
                                <div key={index}>
                                  <Field
                                    component="select"
                                    name={`actuation_areas_id[${index}]`}
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.actuation_areas_id[index]
                                    }
                                  >
                                    <option value={null}>Adicione</option>
                                    {actuationAreas?.map((actuationArea) => (
                                      <option value={actuationArea?._id}>
                                        {actuationArea?.title}
                                      </option>
                                    ))}
                                  </Field>

                                  <button
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)} // remove um grupo do array
                                  >
                                    <FaMinus />
                                  </button>
                                </div>
                              )
                            )}
                        </div>
                      )}
                    />
                  )}
                </div>

                <div className="users">
                  <h3 onClick={() => setWhoIsOpen('users')}>Usuários</h3>
                  <FieldArray
                    name="users_id"
                    render={(arrayHelpers) => (
                      <div>
                        <div className="header">
                          <h2>Selecionar usuários</h2>
                          <div>
                          
                            <input
                              type="text"
                              placeholder="Busque pelo e-mail"
                              onChange={(e) =>
                                debounceInput('users/auth', e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <h3>Usuários selecionados</h3>
                          <div className="userList">
                            {formik.values.users_id.map((user, index) => (
                              <div className="userList__content">
                                <p>{user.name}</p>
                                <p>{user.auth?.email}</p>
                                <button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)} // remove um grupo do array
                                >
                                  <FaMinus />
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>

                        <hr />
                        <table>
                          <thead>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Documento</th>
                          </thead>

                          <tbody>
                            {usersList &&
                              usersList.map((user) => (
                                <tr>
                                  <td>{user.name}</td>
                                  <td>{user.auth?.email}</td>
                                  <td>{user.document}</td>
                                  <td>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.insert(
                                          formik.values.actuation_areas_id
                                            .length,
                                          user
                                        )
                                      } // adiciona mais uma opção de select
                                    >
                                      <FaPlus />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  />
                </div>
              </FilterContainer>
            )
          ) : notification.send_to_all_users ? (
            <h2>Enviada para todos os usuários</h2>
          ) : (
            <div>
              <h2>Enviada para os seguintes filtros</h2>

              <div className="sentFilters">
                {notification?.actuation_areas_id.length > 0 && (
                  <>
                    <h3 style={{ marginBottom: '0.5rem' }}>Áreas de atuação</h3>
                    {notification.actuation_areas_id.map((area) => (
                      <h4 key={area._id}>{area.title}</h4>
                    ))}
                  </>
                )}
                {notification?.users_id.length > 0 && (
                  <table>
                    <th>Usuários</th>
                    {notification.users_id.map((user) => (
                      <tr>
                        <td key={user._id}>{user.name}</td>
                        <td>{user.auth?.email}</td>
                        <td>{user.document}</td>
                      </tr>
                    ))}
                  </table>
                )}
              </div>
            </div>
          )}
        </FormikProvider>
        {isCreate && (
          <div className="formButton">
            <button
              type="button"
              onClick={() => history.push('/notifications')}
            >
              Cancelar
            </button>
            <button disabled={formik.isSubmitting} type="submit">
              {formik.isSubmitting ? (
                <CircularProgress size={14} style={{ margin: 0, padding: 0 }} />
              ) : (
                'Enviar'
              )}
            </button>
          </div>
        )}
      </UserFormComponent>
      {isModalLinkOpen && (
        <ModalLink
          setOpenModalLink={setIsModalLinkOpen}
          linkCallback={handleLinkChange}
          content={link}
        />
      )}
    </>
  );
}
